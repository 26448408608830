// /* VacationList.css */


// .filters-wrapper {
//   button {
//     margin: 5px;
//     padding: 10px 20px;
//     border: none;
//     background-color: #f0f0f0;
//     cursor: pointer;
//     transition: background-color 0.3s ease;
//   }
  
//   button.active {
//     background-color: #007bff;
//     color: #fff;
//   }
  
//   button:hover {
//     background-color: #ddd;
//   }
  
//   input[type="text"] {
//     margin: 10px 0;
//     padding: 5px;
//     width: 200px;
//   }
  
//   input[type="text"] + button {
//     padding: 5px 10px;
//     margin-left: 5px;
//     background-color: #f0f0f0;
//     border: none;
//     cursor: pointer;
//     transition: background-color 0.3s ease;
//   }
  
//   input[type="text"] + button:hover {
//     background-color: #ddd;
//   }

//   .input-filter {
//     align-items: center;
//     display: flex;
//     justify-content: flex-end;

//     input {
//       margin: 5px;
//       padding: 10px 20px;
//     }
//   }
// }


// .link-more {
//   img {
//     height: 200px;
//     width: 100%;
//     transition-duration: .4s;
//   }

//   transition:  ease-in-out;
 

//   &:hover {
   
//     img {
//       opacity: .7;
      
//     }
//   }
// }

// new styles

.localization-container {
  img {
    width: 100%;
    object-fit: contain;
    height: auto;
  }
}

// .back-button-container {
//   .btn-back {
//     border: 1px solid
//   }
// }