// just for now

.carousel {
  margin-bottom: 20px;

  img {
    width: 100%;
    height: 60vh;
    object-fit: cover;
  }
}