@import './styles/breakpoint-mixins.scss';

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px
);

$primary: #4AA6C2; 
$color-price: #28829e;
$white: #FFF;

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

// just for now
main {
  margin-top: 151px;
}

// contact page 

.contact-page {
  address {
    display: flex;
    flex-direction: column;
  }

  .map-container {
    position: relative
    ;
    .loader {
      position: absolute;
      left: 0;
      right: 0;
      background: $primary;
      width: 100%;
      height: 450px;
      background-image: url(../public/images/loader.svg);
      background-size: 60px;
      background-repeat: no-repeat;
      background-position: center;
    }
  }

  iframe {
    width: 100%;
    position: relative;
    z-index: 1;
  }
}

header {
  .navbar {
    background: $white;
    border-bottom: 1px solid $primary;
  }
}

footer {
  background: $primary;
  padding: 20px 0;
  color: $white;
  margin-top: auto;
  margin-bottom: 0;

  h3 {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 12px;
  }

  a {
    color: $white;

    &.telephone-number {
      padding-left: 5px;;
    }
  }

  p {
    margin-bottom: 5px;
  }

  ul {
    list-style-type: none;
    padding: 0;

    li {
      margin-bottom: 5px;
    }
  }

  .social-media-section {
    border-top: 1px solid $white;
    display: flex;
    gap: 30px;
    justify-content: center;
    margin-top: 12px;
    padding-top: 12px;

    @include media-breakpoint-up(lg) {
      gap: 20px;
      justify-content: flex-end;
    }

    a {
      transition: .4s ease-in-out;

      &:hover,
      &:focus {
        .icon {
          &-facebook {
            background-image: url(../public/icons/facebook_reverted.svg);
            background-color: $white;
          }

          &-instagram {
            background-image: url(../public/icons/instagram_reverted.svg);
            background-color: $white;
          }
        }
      }
    }

    .icon {
      background-position: center;
      background-repeat: no-repeat;
      background-size: 30px;
      display: block;
      width: 45px;
      height: 45px;
      border-radius: 50%;
      border: 1px solid $white;
      transition: .4s ease-in-out;

      &-facebook {
        background-image: url(../public/icons/facebook.svg);
      }

      &-instagram {
        background-image: url(../public/icons/instagram.svg);
        background-size: 27px;
      }
    }
  }
}


// details page

.dates-table {
  tr {
    border-bottom: 1px solid $primary;

    &:last-of-type {
      border-bottom: none;
    }
  }

  td {
    padding: 10px;

    &:first-of-type {
      padding-right: 30px;
    }
  }

  thead {
    font-weight: bold;
    border-bottom: 1px solid $primary;
  }
  
}


.back-button-container {
  .btn-back {
    border: 1px solid #f0f0f0;
    border-radius: 0;
    padding: 10px 30px;
    background-color: #f0f0f0;

    &:hover,
    &:focus {
      background-color: #ddd;
    }
  }
}


// vacation list page 



// navigation main

.navbar-brand {
  img {
    height: 100px;
    margin: 12px 0;
    width: auto;
  }
}

.navbar-nav {
  margin-left: auto;
}

.navbar {
  .nav-link {
    font-size: 22px;
  }
}

// navigation main end



/* VacationList.css */


.filters-wrapper {
  align-items: flex-start;
  padding: 0 0 16px;

  button {
    padding: 10px 20px;
    border: none;
    background-color: #f0f0f0;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  button.active {
    background-color: $primary;
    color: #fff;
  }
  
  button:hover {
    background-color: #ddd;
  }
  
  input[type="text"] {
    padding: 5px;
    width: 200px;
    border: 1px solid $primary;
  }
  
  input[type="text"] + button {
    background-color: #f0f0f0;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  input[type="text"] + button:hover {
    background-color: #ddd;
  }

  .input-filter {
    align-items: center;
    display: flex;
    

    @include media-breakpoint-up(lg) {
      justify-content: flex-end;
    }

    input {
      margin: 0 10px 0 0;
      padding: 10px 20px;
    }


  }

  .buttons {
    display: grid;
    grid-template-columns: 1fr 1fr;
    
    @include media-breakpoint-up(xl) {
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    }
    gap: 5px;
  }
}

.filter-type-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;

  .input-wrapper {
    display: grid;
    gap: 10px;
    grid-template-columns: 4fr 1fr;

    input {
      width: 100%;
    }
  }
}

// vacation list end


// footer on bottom of the page

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}


// end of footer bottom of the page


// list of elements

.link-more {
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;
  text-decoration: none;
  color: black;
  height: 100%;
  text-align: center;
  border-bottom: 1px solid #f0f0f0;
  margin-bottom: 12px;

  img {
    margin-bottom: 6px;
    height: 200px;
    width: 100%;
    transition-duration: .4s;
    object-fit: cover;
  }

  transition:  ease-in-out;
 
  .price {
    color: $color-price;
    text-decoration: none;
    font-size: 18px;
    font-weight: bold;
    background-color: $white;
    padding: 6px;
    margin-top: auto;
    margin-bottom: 16px;
    border: 1px solid $color-price;
  }

  &:hover {
   
    img {
      opacity: .7;
    }
  }
}



// end of list of elements


// jumbotron container

.container-jumbotron {
  max-width: 1920px;
  margin: auto
}

// end of jumbotron container 

// mobile menu

.navbar {
  .navbar-toggler {
    border-radius: 0;
    padding: 6px 14px;
    &:not(.collapsed),
    &:focus {
      box-shadow: 0px 0px 0px 3px rgba($color-price, 1)
    }
  }

  .navbar-toggler-icon {
    background-image: url(../public/icons/hamburger.svg);
  }
}



// end of mobile menu


.details-text {
  address {
    span {
      display: block;
    }
  }
}

.regulations {
  h1 {
    font-size: 22px;
  }

  h2 {
    font-size: 18px;
  }

  p {
    margin-bottom: 5px;;
  }

  .letters {
    list-style-type: lower-alpha;
  }
}

// file link

.documents-wrapper {
  ul {
    list-style-type: none;
    padding-left: 0;

    li {

        padding: 10px 10px 10px 38px;
        background-image: url(../public/icons/pdf.svg);
        background-size: 30px;
        background-repeat: no-repeat;
        background-position: center left;
      
    }
  }
}

img.home-content-image {
  width: 100%;
  object-fit: contain;
  height: auto;
  margin-bottom: 10px;
}


// fonts
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+Display:ital,wght@0,100..900;1,100..900&display=swap');

* {
  font-family: "Noto Sans Display", sans-serif;
  font-optical-sizing: auto;
  font-weight: 200;
  font-style: normal;
  font-variation-settings:
    "wdth" 100;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: 400;
}

p, li, a, span, table {
  font-size: 18px;
}

.documents-page {
  h5 {
    display: none;
  }
}